.leaderboard_container{
    width:100%;
    position: relative;
    min-height: 67rem;
    /* overflow: hidden; */
}
.leaderAnimation_container{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.leaderboardAnimation{
    width: 100%;
    height: 100%;
}

.leaderboard_positions_container{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
}
.leaderboard_top3_positions{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    width:100%;
}
.position1_animation_container,.position2_animation_container,.position3_animation_container{
    width: 10rem;
    height: 10rem;
}
.award1Animation,.award2Animation,.award3Animation{
    width: 100%;
    height: 100%;
}
.position2_animation_container,.position3_animation_container{
    margin-top: 4rem;
}

.player_info{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    gap: 0.3rem;
    width: 100%;
}
.player_text{
    width: 100%;
    font-size: 1.6rem;
    font-family: 'Signika Negative',sans-serif;
    font-weight: bold;
}

.leaderboard_table{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    height: auto;
}

.leaderboard_data_container{
    width: 60%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem;
    border-bottom: 0.1rem solid #1F1C3A;
}

.leaderboard_data{
    width: 100%;
    display: grid;
    grid-template-columns: 50px 1fr 1fr;
    gap: 1rem;
    }

.circle_container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.circle{
    width: 3rem;
    height: 3rem;
    background-color: #1F1C3A;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.circle h3{
    color: #fff;
    font-size: 1.5rem;
    font-family: 'Signika Negative',sans-serif;
}

.user{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.3rem;
    font-family: 'Signika Negative',sans-serif;
}

.points{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.3rem;
    font-family: 'Signika Negative',sans-serif;
}

@media screen and (max-width:800px){
    .leaderboard_data_container{
        width: 80%;
    }
}

@media screen and (max-width:600px){
    .leaderboard_data_container{
        width: 100%;
    }
}